var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{attrs:{"method":"post","id":"check-login-form"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Tiêu đề")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-icon',[_vm._v("mdi-edit")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nhập tiêu đề","outlined":""},model:{value:(_vm.exercise.title),callback:function ($$v) {_vm.$set(_vm.exercise, "title", $$v)},expression:"exercise.title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" Key tiêu đề")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Nhập key tiêu đề"},model:{value:(_vm.exercise.seo_title),callback:function ($$v) {_vm.$set(_vm.exercise, "seo_title", $$v)},expression:"exercise.seo_title"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Key mô tả")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","label":"Nhập key mô tả"},model:{value:(_vm.exercise.seo_description),callback:function ($$v) {_vm.$set(_vm.exercise, "seo_description", $$v)},expression:"exercise.seo_description"}}),_c('span',{staticClass:"validate-error text-danger text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v("Dạng bài thưc hành")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.items,"label":"Chọn dạng bài","dense":""},on:{"change":_vm.getExerciseType},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('img',{attrs:{"src":"./images/" + _vm.image}})])],1),_c('v-row',[_c('v-col',{staticClass:"text-center"},[_c('v-btn',{staticClass:"white--text",attrs:{"color":"black","type":"submit","depressed":"","form":"check-login-form"}},[_vm._v("Tiếp tục ")])],1)],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }